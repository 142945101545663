const radioLabel = {
	init: function() {
		const labelsFor = [...document.querySelectorAll('.label-for')]

		labelsFor.map(labelFor => new this.detectLabel(labelFor))
	},

	detectLabel: class {
		constructor(labelFor) {
			this.labelFor = labelFor
			this.input = this.labelFor.querySelector('input')
			this.handleClick()
		}

		handleClick() {
			this.labelFor.addEventListener('click', () => {
				this.selectInput()
			})
		}

		selectInput() {
			if(this.input.type === 'radio' && this.input.checked) {
				return null
			}

			this.input.checked = !this.input.checked
		}
	}
}

export default radioLabel