const documentUpload = {
	init: function() {
		const isAdvancedUpload = function() {
			const div = document.createElement('div')
			return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
		}()

		if (isAdvancedUpload) {

			const inputUploads = [...document.querySelectorAll('.file-upload')]

			inputUploads.map(inputUpload => new this.handleDocumentUpload(inputUpload))
		}
	},

	handleDocumentUpload: class {
		constructor(inputUpload) {
			this.inputUpload = inputUpload
			this.typesContainer = inputUpload.querySelector('.file-upload-types')
		  	this.maxContainer = inputUpload.querySelector('.file-upload-max')
		  	this.fileInput = inputUpload.querySelector('input[type="file"]')
		  	this.uploadImage = inputUpload.querySelector('.upload-image')
		  	this.initialImage = this.uploadImage.src

			this.droppedFiles = false
			this.ValidImageTypes = ["image/gif", "image/jpeg", "image/png", "image/gif", "image/tif", "application/pdf"]
			this.eventListeners = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']
		
			this.attachEvents()
		}

		fireChange() {
			if (this.fileInput.files && this.fileInput.files[0]) {
				if (this.fileInput.files[0]['size'] <= 2000000) {
					this.maxContainer.classList.remove('error')
				} else {
					this.maxContainer.classList.add('error')

					return
				}

				if (this.ValidImageTypes.indexOf(this.fileInput.files[0]['type']) >= 0) {
					this.typesContainer.classList.remove('error')
					this.readURL()
				} else {
					this.typesContainer.classList.add('error')
					this.uploadImage.src = this.initialImage
					this.fileInput.value = ''
				}
			} else {
				this.uploadImage.src = this.initialImage
				this.fileInput.value = ''
			}
		}

		attachEvents() {
			this.eventListeners.map(eventListener => {
		  		this.inputUpload[`on${eventListener}`] = (e) => {
		  			e.preventDefault()

		  			if(eventListener == ('dragover' || 'dragenter')) {
		  				this.inputUpload.classList.add('is-dragover')
		  			} 

		  			if(eventListener == ('dragleave' || 'dragend' || 'drop')) {
		  				this.inputUpload.classList.remove('is-dragover')
		  			}

		  			if(eventListener == 'drop') {
		  				this.droppedFiles = e.dataTransfer.files

		  				if(this.ValidImageTypes.indexOf(this.droppedFiles[0]['type']) >= 0)  {
		  					this.typesContainer.classList.remove('error')

							if(this.droppedFiles[0]['size'] <= 2000000) {
								this.maxContainer.classList.remove('error')
								this.fileInput.classList.add('active')

								if (document.documentMode || /Edge/.test(navigator.userAgent)) {
									this.fileInput.files[0] = this.droppedFiles[0]
									this.fireChange()									
								} else {
									this.fileInput.files = this.droppedFiles
								}
							} else {
								this.maxContainer.classList.add('error')
							}
						} else {
		  					this.typesContainer.classList.add('error')

							if(this.droppedFiles[0]['size'] <= 2000000) {
								this.maxContainer.classList.remove('error')
							} else {
								this.maxContainer.classList.add('error')
							}
						}
		  			}
		  		}
		  	})

			this.readURL()

			this.fileInput.onchange = () => {
				this.fireChange()
			}
		}

		readURL(input){
			var reader = new FileReader()

			reader.addEventListener('load', e => {
				if (this.fileInput.files[0]['type'] == "application/pdf") {
					this.uploadImage.src = 'assets/icons/pdf.png'
				} else {
					this.uploadImage.src = e.target.result
				}
			})

			if(this.fileInput.files && this.fileInput.files[0]) {
			    if (this.fileInput.files[0]['type'] && this.ValidImageTypes.indexOf(this.fileInput.files[0]['type']) >= 0) {
		     	   reader.readAsDataURL(this.fileInput.files[0])
			    } else {
			    	if(this.initialImage) {
			    		this.uploadImage.src = this.initialImage
			    	}
			    }
			}
		}

	}
}

export default documentUpload
