import utility from './utility'

const lotteryTabs = {
	init: function() {
		const tabToggles = [...document.querySelectorAll('.tab-toggle')]

		tabToggles.map(tabToggle => {
			tabToggle.querySelector('input[type="checkbox"]').onchange = () => {
				tabToggle.querySelector('*[data-tab]:not(.active)').click()
			}
		})

		const tabNavLinks = [...document.querySelectorAll('*[data-tab]')]

		tabNavLinks.map(tabNavLink => new this.tabActions(tabNavLink))
	},

	tabActions: class {
		constructor(tabNavLink) {
			this.tabLink = tabNavLink
			this.tabData = tabNavLink.dataset.tab

			if(this.tabLink.dataset.closetab !== undefined) {
				this.tabData = tabNavLink.dataset.closetab
			}

			this.tabContent = document.querySelector(`.tab-content#${this.tabData}`)
			this.tabSiblings = [...this.tabContent.parentNode.children]

			this.handleClick()

			if(this.tabContent.classList.contains('active') && this.tabContent.classList.contains('nav-tab')) {
				setTimeout(() =>{
					this.adjustNavTabs()
				}, 300)
			}

		}

		handleClick() {

			this.tabLink.onclick = () => {
				if(window.innerWidth >= 980 && this.tabLink.dataset.mobiletab == 'true') {
					return
				}

				if(this.tabLink.dataset.closetab !== undefined) {
					this.closeTabs()
				} else {
					this.activateTabs()
					this.activateLinks()
				}	
			}
		}

		activateTabs() {
			const activeTab = this.tabSiblings.find((el) => {
				return el.classList.contains('active')
			})

			if(activeTab !== undefined && this.tabLink.dataset.tab !== this.tabLink.dataset.tabset) {
				activeTab.classList.remove('active')
			}

			this.tabContent.classList.add('active')
			
		}

		activateLinks() {
			let tabNavLinks

			if(this.tabLink.dataset.tabset !== undefined) {
				tabNavLinks = [...document.querySelectorAll(`*[data-tabset="${this.tabLink.dataset.tabset}"]`)]
			} else {
				tabNavLinks = [...this.tabLink.parentNode.children]
			}

			tabNavLinks.map(tabNavLink => {
				if(tabNavLink.dataset.tab == undefined) return

				if(tabNavLink == this.tabLink) {
					if(tabNavLink.classList.contains('active') && this.tabLink.dataset.tabset !== undefined) {
						document.querySelector(`.tab-content#${tabNavLink.dataset.tab}`).classList.remove('active')

						if(tabNavLink.dataset.tabinitial !== undefined) {
							document.querySelector(`.tab-content#${tabNavLink.dataset.tabinitial}`).classList.add('active')

							if(tabNavLink.dataset.tabinitial === tabNavLink.dataset.tab) return
						}
					}

					if(this.tabLink.dataset.tabset !== undefined) {
						tabNavLink.classList.toggle('active')
					} else {
						tabNavLink.classList.add('active')
					}


					if(this.tabLink.dataset.subtab !== undefined) {

						[...document.querySelectorAll(`*[data-tab="${this.tabLink.dataset.subtab}"`)].map(activateLink => {

							if(this.tabLink.dataset.subtab == this.tabLink.dataset.tab) {
								activateLink.click()
							} else {
								activateLink.classList.add('active')
							}
						})
					}
				} else {
					tabNavLink.classList.remove('active')
				}
			})

			if(this.tabContent.classList.contains('nav-tab')) {
				this.adjustNavTabs()
			}
		}

		adjustNavTabs() {
			// Adjust height of tab to subtract header height (for overflow to function properly)
				const headerHeight = document.querySelector('#header').offsetHeight
				const tabHeight = this.tabContent.offsetHeight

				this.tabContent.style.height = `calc(100vh - ${headerHeight - 5}px)`
				this.tabContent.scrollTop = 0

			// Disable HTML Scroll if tab is active

				if(document.querySelector(`.nav-tab.active`)) {
					utility.htmlScroll('hidden')
				} else {
					utility.htmlScroll('initial')
				}
		}

		closeTabs() {
			this.tabSiblings.map(activeTab => {
				if(activeTab.classList.contains('tab-content')) {
					activeTab.classList.remove('active')
				}
			})
		}
	}
}

export default lotteryTabs