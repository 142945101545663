import utility from './utility'

const styleSelect = {
    init: (el) => {
        var selects = document.querySelectorAll(el);

        if (selects.length > 0) {
            for (var i = 0; i <= selects.length; i++) {
                if (selects[i]) {
                    selects[i].style.display = 'none'
                    var classList = selects[i].classList
                    var selectOptions = selects[i].querySelectorAll('option')
                    var selectName = selects[i].name
                    var selectedIndex = selects[i].selectedIndex
                    var hasSearch = selects[i].dataset.search
                    if(hasSearch) classList += ' has-search'


                    if (selectOptions.length > 0)
                        var optionsStart = '<div class="select-inner"><ul class="select-options">'
                        var optionsList = ''

                    for (var x = 0; x <= selectOptions.length; x++) {
                        if (selectOptions[x]) {
                            var optionContent = selectOptions[x].innerHTML
                            var optionValue = selectOptions[x].value
                            var optionClass = selectOptions[x].classList
                            var optionImg = selectOptions[x].getAttribute('data-img')

                            let dataSets = ''

                            Object.keys(selectOptions[x].dataset).map(key => {
                                dataSets += `data-${key}="${selectOptions[x].dataset[key]}" `
                            })
                            

                            if (x == selectedIndex) {
                                var selectedClass = selectOptions[selectedIndex].classList
                                var selectedValue = `<span class="select-rendered ${selectedClass} ${optionImg ? 'has-image' : ''}" data-name="${selectName}">
                                    ${(optionImg ? `<img src="/assets/${optionImg}" alt="" />` : '')}  
                                    ${optionContent} 
                                </span><span class="dropdown-arrow"></span>`
                                
                                if(optionValue == '') continue    

                                optionsList = optionsList + `<li tabindex="0" class="select-option selected ${optionClass} ${optionImg ? 'has-image' : ''}" ${dataSets} data-value="${optionValue}" data-name="${selectName}" data-index="${x}">${(optionImg ? `<img src="/assets/${optionImg}" alt="" />` : '')} ${optionContent}</li>`
                            } else {
                                optionsList = optionsList + `<li tabindex="0" class="select-option ${optionClass} ${optionImg ? 'has-image' : ''}"  ${dataSets} data-value="${optionValue}" data-name="${selectName}" data-index="${x}">${(optionImg ? `<img src="/assets/${optionImg}" alt="" />` : '')} ${optionContent}</li>`
                            }
                        }
                    }

                    var optionsEnd = '</ul></div>'

                    selects[i].insertAdjacentHTML('afterend', '<div class="select-dropdown ' + classList + '">' + selectedValue + optionsStart + optionsList + optionsEnd + '</div>');
                }
            }

            var selectDropdown = document.querySelectorAll('.select-dropdown')

            Array.prototype.forEach.call(selectDropdown, function (currentDropdown, i) {
                var max_w = 0;
                var selectRendered = currentDropdown.querySelector('.select-rendered');
                var selectOptions = currentDropdown.querySelector('.select-options');
                var selectOption = currentDropdown.querySelectorAll('.select-option');

                if(currentDropdown.classList.contains('has-search')) {
                    hasSearch = document.createElement('input')
                    hasSearch.setAttribute('type', 'text')
                    hasSearch.setAttribute('placeholder', 'Search...')
                    hasSearch.classList = 'select-search'
                    selectOptions.parentNode.insertBefore(hasSearch, selectOptions)

                    hasSearch.oninput = event => {
                        var value = event.target.value

                        selectOption.forEach(option => {
                            var searchPlaceholder = option.innerHTML.toLowerCase()
                            const valLower = option.dataset.value.toLowerCase()
                        
                            if (!valLower.includes(value.toLowerCase()) && !searchPlaceholder.includes(value.toLowerCase())) {
                                option.classList.add('hidden')
                            } else {
                                option.classList.remove('hidden')
                            }
                        })
                    }
                }

            
                currentDropdown.addEventListener("click", function (event) {
                    if(event.target.type !== 'text') {
                        utility.toggleClass(this, 'dropdown-active')

                        if (hasSearch) {
                            hasSearch.value = ''

                            selectOption.forEach(option => {
                                // option.classList.remove('hidden')
                            })
                        }
                    }
                })

                document.addEventListener('click', function (event) {
                    var isClickInside = currentDropdown.contains(event.target);

                    if (!isClickInside) {
                        utility.removeClass(currentDropdown, 'dropdown-active')
                        
                        if(hasSearch) {
                            hasSearch.value = ''

                            selectOption.forEach(option => {
                                // option.classList.remove('hidden')
                            })
                        }
                    }
                });

                let lastFocus

                [...document.querySelectorAll('.select-option, .select-search')].map(selectOption => {
                    selectOption.onblur = () => {
                        const focusedDropdown = utility.findAncestor(selectOption, 'select-dropdown')

                        if (focusedDropdown.classList.contains('dropdown-active') && focusedDropdown.contains(lastFocus)) {
                            focusedDropdown.click()
                        }
                    }

                    selectOption.onfocus = () => {
                        lastFocus = selectOption
                        const focusedDropdown = utility.findAncestor(selectOption, 'select-dropdown')
                        if (!focusedDropdown.classList.contains('dropdown-active')) {
                            focusedDropdown.click()
                        }
                    }

                    selectOption.onkeydown = e => {
                        if(e.keyCode === 13) {
                            selectOption.click()
                        }
                    }
                })

                Array.prototype.forEach.call(selectOption, function (el, i) {

                    if (max_w < el.offsetWidth) {
                        max_w = el.offsetWidth;
                    }

                    el.addEventListener("click", function () {
                        var selectOptionName = this.getAttribute('data-name')
                        var selectOptionValue = this.getAttribute('data-value')
                        var selectOptionIndex = this.getAttribute('data-index')
                        var selectOptionClass = this.classList['value']
                        var targetContainer = utility.findAncestor(this, 'select-dropdown')
                        var targetSelect = targetContainer.previousElementSibling;
                        var targetRender = targetContainer.querySelectorAll('.select-rendered[data-name="' + selectOptionName + '"]')
                        var targetOptions = targetContainer.querySelectorAll('.select-option[data-name="' + selectOptionName + '"]')
                        targetSelect.selectedIndex = selectOptionIndex
                        targetRender[0].innerHTML = this.innerHTML

                        var event = document.createEvent('HTMLEvents');
                        event.initEvent('change', true, false);
                        targetSelect.dispatchEvent(event);

                        for (var y = 0; y <= targetOptions.length; y++) {
                            if (targetOptions[y]) {
                                if (this === targetOptions[y]) {
                                    utility.addClass(this, 'selected')
                                    targetRender[0].classList = 'select-rendered ' + selectOptionClass
                                } else {
                                    utility.removeClass(targetOptions[y], 'selected')
                                }
                            }
                        }
                    });
                })
                // currentDropdown.style.minWidth = max_w + 'px';
            })
        }
    }
}

export default styleSelect