import utility from './utility'

const lotteryHowTo = {
	init: function() {
		const divisionSliders = [...document.querySelectorAll('.division-slider')]

		divisionSliders.map(divisionSlider => new this.HowToDivisions(divisionSlider))
	},

	HowToDivisions: class {
		constructor(divisionSlider) {
			this.divisionSlider = divisionSlider
			this.divisions = JSON.parse(divisionSlider.dataset.divisions)
			this.divisionsContainer = utility.findAncestor(divisionSlider, 'divisions-breakdown').querySelector('.divisions-container')

			this.handleInput()
		}

		handleInput() {
			this.divisionSlider.oninput = () => {
				this.buildDivision(this.divisionSlider.value)
			}

			this.divisionSlider.onchange = () => {
				this.buildDivision(this.divisionSlider.value)
			}
		}

		buildDivision(divisionNumber) {
			const currentDivision = this.divisions[divisionNumber - 1]

			let numberType = ''

			const numbersLine = currentDivision.map(division => {

				const html = [...Array(division).keys()].map(() => 
					`<div class="lottery-number ${numberType === 'number' ? 'lucky' : ''}"><span></span></div>`
				).join('')

				numberType = 'number'

				return html

			}).join('')

			this.divisionHTML = this.divisionsContainer.innerHTML = `
				<h4>Division ${divisionNumber}</h4>
				<p>${currentDivision[0]} numbers ${currentDivision[1] ? `+ ${currentDivision[1]} Lucky Stars` : ''}</p>
				<div class="lottery-numbers lottery-numbers-row center">
					${numbersLine}
				</div>
			`

			this.changeDivision
		}

		changeDivision() {
			this.divisionsContainer.innerHTML = this.divisionHTML
		}
	}
}

export default lotteryHowTo