const accountFunds = {
	init: function() {
		const accountFundsForms = [...document.querySelectorAll('.account-funds')]

		accountFundsForms.map(fundsForm => new this.fundActions(fundsForm))
	},

	fundActions: class {
		constructor(fundsForm) {
			this.form = fundsForm
			this.options = [...this.form.querySelectorAll('.choose-plays input[type="radio"]')]
			this.otherLabel = this.form.querySelector('.choose-plays .input-other')
			this.otherInput = this.form.querySelector('.choose-plays .input-other input')
			this.valueInput = this.form.querySelector('.account-funds-value')
			this.submitButton = this.form.querySelector('button[type="submit"]')

			this.handleClick()
		}

		handleClick() {
			this.options.map(option => {
				option.onclick = () => {
					this.detectValue(option)
				}
			})
		}

		detectValue(option) {
			if(option.value == 'other') {
				this.otherValues()

				return
			}

			this.otherLabel.classList.remove('active')
			this.changeValues(option.value)
		}

		otherValues() {
			if(this.otherInput.value) {
				this.otherInput.value = ''
			}
			this.changeValues('00.00')
			this.otherLabel.classList.add('active')

			this.otherInput.oninput = () => {
				let otherValue = this.otherInput.value

				if(this.otherInput.value == '') {
					otherValue = '00.00'
				}

				this.changeValues(otherValue)
			}
		}

		changeValues(value) {
			this.valueInput.value = value
			this.submitButton.innerHTML = `Add $${value}`
		}
	}
}

export default accountFunds