import utility from './utility'
import createNotification from './createNotification'

const boltIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>'
const heartIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
const iconClose = `<svg width="11px" height="12px" viewBox="0 0 11 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Pop-up-|-If-you-win---Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-281.000000, -27.000000)"><g id="ICON-Close" transform="translate(274.000000, 20.000000)" fill="#FFFFFF"><path d="M17.098,16.042 C17.274,16.24 17.428,16.548 17.428,16.878 C17.428,17.472 16.944,18.154 16.152,18.154 C15.8,18.154 15.404,18.044 15.184,17.736 L12.478,14.392 L9.816,17.692 C9.552,18.044 9.2,18.154 8.848,18.154 C8.034,18.154 7.572,17.472 7.572,16.878 C7.572,16.548 7.704,16.24 7.902,16.042 L10.85,12.5 L8.1,9.354 C7.924,9.156 7.77,8.826 7.77,8.518 C7.77,7.858 8.32,7.22 9.068,7.22 C9.398,7.22 9.728,7.286 10.014,7.638 L12.5,10.608 L14.986,7.638 C15.25,7.286 15.602,7.22 15.932,7.22 C16.68,7.22 17.23,7.858 17.23,8.518 C17.23,8.826 17.076,9.156 16.9,9.354 L14.15,12.5 L17.098,16.042 Z" id="x"></path></g></g></svg>`
const iconTrash = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>`


const lotteryGame = {
	init: function () {
		const _this = this
		const playGames = [...document.querySelectorAll('.play-now--game')]

		playGames.map(playGame => {
			const gameType = playGame.dataset.game
			const gameNumbers = JSON.parse(playGame.dataset.numbers)
			let selectedNumbers

			if(playGame.dataset.selectednumbers) {
				selectedNumbers = JSON.parse(playGame.dataset.selectednumbers)
			}

			new _this.buildGame(playGame, gameType, gameNumbers, selectedNumbers)
		})

		const selectsAddFavourite = [...document.querySelectorAll('.select-add-lottery')]

		selectsAddFavourite.map(selectAddFavourite => {

			selectAddFavourite.onchange = () => {
				const selectedOption = selectAddFavourite.options[selectAddFavourite.selectedIndex]
				const selectedValue = selectAddFavourite.value
				const title = selectedOption.dataset.title
				const image = selectedOption.dataset.image

				const newFavourite = document.createElement('form')
				newFavourite.classList.add('lottery-card')
				newFavourite.classList.add('lottery-card-account')
				newFavourite.classList.add('lottery-card-favourite')

				newFavourite.innerHTML = `<span class='card-trash'>${iconTrash}</span><div class="favourite-lottery play-now--game" data-numbers="{&quot;number&quot;: [52, 7], &quot;lucky&quot;: [26, 2]}" data-haszero="true">
					<div class="favourite-header"><img class="lottery-flag flag-round" src="assets/flags/${image}" alt="${title}">
						<h4>${title}</h4>
					</div>

					<div class="game-number-lines no-favourite"></div>
					<p class="add-game button button-orange active">Add favourite</p>
					<label class="input input-green-solid">
						<input type="submit" value="Play now" />
					</label>
				</div>`

				document.querySelector('.favourites-container').appendChild(newFavourite)
				new _this.buildGame(newFavourite.querySelector('.play-now--game'), 'custom', {'number': [52, 7], 'lucky': [26, 2]})

				const nextSibling = selectAddFavourite.nextSibling.querySelector(`li.select-option[data-value='${selectedValue}']`)
				nextSibling.parentNode.removeChild(nextSibling)	

				if(!selectAddFavourite.nextSibling.querySelector('li.select-option')) {
					selectAddFavourite.nextSibling.querySelector('.select-rendered').innerHTML = 'No more lotteries available'
				}
			}
		})

		const expandFavourites = [...document.querySelectorAll('.number-lines-expand')]
		
		expandFavourites.map(expand => {
			const expandText = expand.innerHTML
			const expandLessText = expand.dataset.subtext

			expand.onclick = () => {
				const expandNumberLines = expand.parentNode.querySelector('.game-number-lines')
				expandNumberLines.classList.toggle('number-lines-minimised')
				// expand.parentNode.removeChild(expand)

				if(expandLessText) {
					if(expandNumberLines.classList.contains('number-lines-minimised')) {
						expand.innerHTML = expandText
					} else {
						expand.innerHTML = expandLessText
					}
				}
			}
		})
	},

	buildGame: class {
		constructor(playGame, gameType, gameNumbers, selectedNumbers) {
			this.gameCost = this.gameCost.bind(this)

			this.playGame = playGame
			this.gameType = gameType
			this.gameNumbers = gameNumbers
			this.selectedNumbers = selectedNumbers

			this.gamePrice = this.playGame.dataset.price
			this.totalCostContainer = this.playGame.querySelector('.total-cost')
			this.totalCostInput = this.playGame.querySelector('.total-cost-input')

			this.numberLines = playGame.querySelector('.game-number-lines')
			this.linesCount = [...this.numberLines.querySelectorAll('.game-number-line')].length + 1

			this.submitButton = playGame.querySelector('*[type="submit"]')
			
			this.handleButtonClick()
			
			if(this.submitButton) {
				this.submitButton.addEventListener('click', e => {
					this.handleSubmit(e)
				})
			}

			if(this.selectedNumbers != undefined) {
				this.selectedNumbers.map(selectedNumber => {
					this.addGame(1, 'selected', selectedNumber)
				})
			}
			
			if(this.gameType == 'custom' && this.linesCount == 1) {
				this.addGame(1)
			}


			if(this.gameType != undefined) {
				this.choosePlays()
				this.handleSlider()
				this.handlePlusGame()
				this.handleMultiDraw()
			}
		}

		handleButtonClick() {
			this.addGameButton = this.playGame.querySelector('.add-game')

			if(this.addGameButton == undefined) return

			this.addGameButton.onclick = () => {

				if(this.addGameButton.classList.contains('disabled')) {
					window.scrollBy({top: this.numberLines.querySelector('.game-numbers--container').parentNode.getBoundingClientRect().top - document.querySelector('#header').offsetHeight - 10, left: 0, behavior: 'smooth' })
					createNotification('Please complete started game before adding another one.')

					return
				}

				this.addGame(1)
			}
		}

		handleSubmit(e) {
			let activeSelect = this.playGame.querySelector('.select-numbers')

			if(activeSelect) {
				activeSelect.click()
				activeSelect = this.playGame.querySelector('.select-numbers')

				if(activeSelect) {
					e.preventDefault()
				}
			}
		}

		choosePlays() {
			const choosePlays = [...this.playGame.querySelectorAll('.choose-plays-container input')]

			choosePlays.map(choosePlay => {
				if(choosePlay.type == 'radio') {
					choosePlay.onchange = () => {
						this.linesCount = 1
						const customInput = this.playGame.querySelector('.choose-plays-container input[type="number"]')
						
						if(customInput != null) {
							customInput.value = ''
						}

						this.addGame(choosePlay.value)
					}
				} else {
					choosePlay.oninput = () => {
						this.linesCount = 1
						const checkedRadio = this.playGame.querySelector('.choose-plays-container input[type="radio"]:checked')
					   	
					   	if(checkedRadio != null && checkedRadio.checked) {
							this.numberLines.innerHTML = ''
					   		checkedRadio.checked = false
					   	}

					    if (choosePlay.value.length > 2) {
					        choosePlay.value = choosePlay.value.slice(0,2)
					    	return
					    }

					    this.addGame(choosePlay.value)

					}
				}
			})
		}

		addGame(gamesQty, gameType = this.gameType, selectedNumbers) {
			if(gamesQty != 1 && gameType != 'favourite') {
				this.numberLines.innerHTML = ''
			}

			if(gameType === 'favourite') {
				window.scrollBy({
					top: this.numberLines.getBoundingClientRect().bottom - document.querySelector('#header').offsetHeight - 10, 
					left: 0, 
					behavior: 'smooth' 
				})
			}

			for(var i = 0; i < gamesQty; i++) {
				new lotteryGame.gameLine({
					numberLines: this.numberLines,  
					playGame: this.playGame, 
					gameType: gameType ? gameType : 'custom',
					gameNumbers: this.gameNumbers,
					linesCount: this.linesCount,
					addGameButton: this.addGameButton,
					gameCost: this.gameCost,
					selectedNumbers: selectedNumbers && selectedNumbers.length > 1 ? selectedNumbers[i] : selectedNumbers,
					favouriteGame: gameType === 'favourite' ? true : false
				})
			}			
		}

		handleSlider() {
			this.priceSlider = this.playGame.querySelector('.price-slider')

			if(!this.priceSlider) return

			this.priceSlider.oninput = () => {
				this.gameCost()
			}

			this.priceSlider.onchange = () => {
				this.gameCost()
			}
		}

		handlePlusGame() {
			this.plusGame = this.playGame.querySelector('input[name="draws_plus_game"]:checked')

			const plusGames = [...this.playGame.querySelectorAll('input[name="draws_plus_game"]')]
			
			plusGames.map(plusGame => {
				plusGame.onchange = () => {
					this.plusGame = plusGame
					this.gameCost()
				}
			})

		}

		handleMultiDraw() {
			const multiDraws = [...this.playGame.querySelectorAll('.draw-multipliers input')]

			multiDraws.map(multiDraw => {
				multiDraw.onchange = e => {
					this.gameCost()
				}
			})
		}

		gameCost() {
			const gameLines = this.playGame.querySelectorAll('.number-line--numbers').length

			if(!gameLines && this.playFavouritesButton != undefined) this.playFavouritesButton.classList.remove('disabled')

			if(!this.gamePrice) return

			const costPlus = Number(this.plusGame.value)
		
		
			const costMultiplier = this.priceSlider
				? this.priceSlider.value
				: this.playGame.querySelectorAll('.draw-multipliers input:checked')[0].value

			
			let totalCost = (Number(this.gamePrice) + costPlus) * Number(costMultiplier) * gameLines
			totalCost = Number(totalCost).toFixed(2)

			this.totalCostContainer.innerHTML = `$${totalCost}`
			this.totalCostInput.value = totalCost
		}
	},

	gameLine: class {
		constructor(props) {
			this.numberLines = props.numberLines
			this.numberLine = props.numberLine

			if(!this.numberLine) {
				this.numberLine = document.createElement('div')
				this.numberLines.appendChild(this.numberLine)
				this.numberLine.classList.add('game-number-line')
			}

			this.gameNumbers = props.gameNumbers
			this.gameType = props.gameType
			this.addGameButton = props.addGameButton
			
			this.gameCost = props.gameCost

			this.selectedNumbers = props.selectedNumbers
			this.favouriteGame = props.favouriteGame
			
			if(this.selectedNumbers == undefined) {
				this.selectedNumbers = {}
				this.createNumbersArr()
			}

			if(this.gameType == 'quick') {
				this.createQuickPlay()
			} else if(this.gameType == 'custom') {
				this.createCustomPlay()
			} else {
				this.createQuickPlay(false)
			}

		}

		createNumbersArr() {
			Object.keys(this.gameNumbers).map(gameType => {
				const picksQty = this.gameNumbers[gameType][1]
				this.selectedNumbers[gameType] = [...Array(picksQty)]
			})
		}

		removeGame() {
			this.numberLine.querySelector('.remove-line').onclick = () => {
				this.numberLine.classList.remove('added')

				setTimeout(() => {
					if(this.numberLine.parentNode == null) return

					this.numberLine.parentNode.removeChild(this.numberLine)
					
					this.updateLinesCount()
					this.adjustLineNumbers()

					if(this.gameCost) {
						this.gameCost()
					}

					const openGame = this.numberLines.querySelector('.game-numbers--container')

					if (this.addGameButton && !openGame) {
						this.addGameButton.classList.remove('disabled')
					}

				}, 250)
			}
		}

		selectFavourite() {
			this.numberLine.querySelector('.favourite-line').onclick = () => {
				this.numberLine.querySelector('.favourite-line').classList.toggle('active')
			}
		}

		editGame() {
			[...this.numberLine.querySelectorAll('.edit-line')].map(editLine => editLine.onclick = () => {
				const openGame = this.numberLines.querySelector('.game-numbers--container')

				if(openGame) {
					createNotification('Please complete started game before proceeding.')
					this.scrollToGame(openGame.parentNode)

					return
				}

				if(this.addGameButton != undefined) {
					this.addGameButton.classList.add('disabled')
				}

				this.createCustomPlay()
			})
		}

		quickSelect() {
			this.quickSelectButton = this.numberLine.querySelector('.quick-select')

			this.quickSelectButton.onclick = () => {
				this.luckySelectLock = false

				const shuffleLock = Object.values(this.selectedNumbers).find(undefinedNumb => {
					if(undefinedNumb.indexOf(undefined) > -1) {
						return true
					}
				})

				if(this.quickSelectLock) return

				[...this.numberLine.querySelectorAll('.custom-numbers')].map(customNumbers => {
					this.quickSelectLock = true
					const containerType = customNumbers.dataset.gametype
					let notSelectedLength = [...customNumbers.querySelectorAll('.custom-selected:not(.selected)')].length
					let notSelectedNumbers = [...customNumbers.querySelectorAll('.custom-selection:not(.selected)')].sort(() => {return 0.5 - Math.random()}).splice(0, notSelectedLength)
					const selectedNumbers =  [...customNumbers.querySelectorAll('.custom-selection.selected')]

					let time = 0

					if(notSelectedLength == 0 && !this.luckySelectLock && !!shuffleLock == false) {
						this.quickSelectLock = true

						this.selectedNumbers[containerType].map((emptySelected, index) => {
							this.selectedNumbers[containerType][index] = undefined
 						})

						selectedNumbers.map(selectedNumber => {
							selectedNumber.classList.remove('selected')
							customNumbers.querySelector(`.custom-selected[data-number="${selectedNumber.dataset.number}"]`).classList.remove('selected')
							customNumbers.querySelector(`.custom-selected[data-number="${selectedNumber.dataset.number}"] span`).innerHTML = ''
							customNumbers.querySelector(`.custom-selected[data-number="${selectedNumber.dataset.number}"]`).dataset.number = ''
						})

						notSelectedLength = [...customNumbers.querySelectorAll('.custom-selected:not(.selected)')].length
						notSelectedNumbers = [...customNumbers.querySelectorAll('.custom-selection:not(.selected)')].sort(() => {return 0.5 - Math.random()}).splice(0, notSelectedLength)
					} else {
						this.luckySelectLock = true
					}

					notSelectedNumbers.map((notSelectedNumber, index) => {
						setTimeout(() => {
							const allSelected = [...customNumbers.querySelectorAll('.custom-selected')]
							const emptySelected = customNumbers.querySelector(`.custom-selected[data-number=""]`)

							notSelectedNumber.classList.add('selected')
							emptySelected.classList.add('selected')
							this.selectedNumbers[containerType][allSelected.indexOf(emptySelected)] = parseInt(notSelectedNumber.dataset.number)

							customNumbers.querySelector(`.custom-selected[data-number=""] span`).innerHTML = notSelectedNumber.dataset.number
							emptySelected.dataset.number = notSelectedNumber.dataset.number

							if(![...this.numberLine.querySelectorAll('.custom-selected:not(.selected)')].length) {
								setTimeout(() => {
									this.quickSelectLock = false
								}, 150)
							}
						}, time)

						time += 100
					})
				})
			}
		}

		updateLinesCount() {
			this.linesCount = [...this.numberLines.querySelectorAll('.game-number-line')].indexOf(this.numberLine) + 1
		}

		createQuickPlay(shuffle = true) {
			this.lineHTML(shuffle)

			setTimeout(() => {
				this.numberLine.classList.add('added')
			}, 10)

		}

		createCustomPlay() {
			this.numberLine.innerHTML = this.customPlayHTML()
			this.customNumberSelection()
			this.removeGame()
			this.quickSelect()

			setTimeout(() => {
				this.numberLine.classList.add('added')
			}, 10)
		}

		customPlayHTML() {
			this.updateLinesCount()

			if(this.addGameButton != undefined) {
				this.addGameButton.classList.add('disabled')
			}

			const numbersHTML = Object.keys(this.gameNumbers).map(gameType => {

				const numbersQty = this.gameNumbers[gameType][0]
				const picksQty = this.gameNumbers[gameType][1]

				const customSelected = this.selectedNumbers[gameType].map(selectedNumber => {
					return `<p class='custom-selected lottery-number-${gameType} ${selectedNumber != undefined ? 'selected' : ''}' data-number='${selectedNumber != undefined ? selectedNumber : ''}'><span class="lottery-number">${selectedNumber != undefined ? selectedNumber : ''}</span></p>`
				}).join('')
					
				const customSelection = this.newNumbersArr(numbersQty).map((number, index) => {
					const selectedIndex = this.selectedNumbers[gameType].indexOf(number)

					return `<p class='custom-selection ${selectedIndex > -1 ? 'selected' : ''}' data-number="${number}"><span class="lottery-number">${number}</span></p>`
				}).join('')

				return `<div class="custom-numbers custom-numbers--${gameType}" data-gametype="${gameType}">
						<p class="custom-numbers--title">${gameType == 'number' ? `<span class='quick-select'>${boltIcon}</span>` : ''} Choose your lucky numbers ${gameType == 'number' ? `<span class="remove-line lottery-close">${iconClose}</span>` : ''}</p>
						<div class="custom-numbers--selected">
							${customSelected}
						</div>
						<div class="custom-numbers--selection">
							${customSelection}
						</div>
					</div>`
			}).join('')

			return `<p class="game-count">Game ${this.linesCount}</p>
				<div class="game-numbers--container">
					${numbersHTML}
					<div class="custom-numbers--action">
						<button class="select-numbers button button-blue" type="button">Select</button>
					</div>
				</div>`
		}

		customNumberSelection() {
			const customNumbersContainers = [...this.numberLine.querySelectorAll('.custom-numbers')]

			customNumbersContainers.map(customNumbersContainer => {
				const customSelections = [...customNumbersContainer.querySelectorAll('.custom-selection')]
				const customSelected = [...customNumbersContainer.querySelectorAll('.custom-selected')]
				const containerType = customNumbersContainer.dataset.gametype

				customSelections.map(customSelection => {
					customSelection.onclick = () => {
						if(this.quickSelectLock) return

						const number = customSelection.dataset.number
						const emptySelected = customNumbersContainer.querySelector('.custom-selected[data-number=""]')

						if(!customSelection.classList.contains('selected') && emptySelected != null) {
							emptySelected.classList.add('selected')
							emptySelected.dataset.number = number
							emptySelected.querySelector('.lottery-number').innerHTML = number
						} else {
							const selectedNumber = customNumbersContainer.querySelector(`.custom-selected[data-number="${number}"]`)
							
							if(selectedNumber == null) return
							
							selectedNumber.classList.remove('selected')
							selectedNumber.dataset.number = ''
							selectedNumber.querySelector('.lottery-number').innerHTML = number
						}

						const numberIndex = this.selectedNumbers[containerType].indexOf(parseInt(number))

						if(this.selectedNumbers[containerType][numberIndex] > -1) {
							this.selectedNumbers[containerType][numberIndex] = undefined
						} else {
							this.selectedNumbers[containerType][this.selectedNumbers[containerType].indexOf(undefined)] = parseInt(number)
						}

						customSelection.classList.toggle('selected')
					}

					customSelected.map(selected => {
						selected.onclick = () => {
							if(this.quickSelectLock) return

							const selectedDataNumber = selected.dataset.number

							if(selectedDataNumber) {
								customNumbersContainer.querySelector(`.custom-selection[data-number="${selectedDataNumber}"]`).click()
							}
						}
					})
				})
			})
			
			this.applySelectNumbers()

		}

		applySelectNumbers() {
			this.selectNumbersButton = this.numberLine.querySelector('.select-numbers')

			this.selectNumbersButton.onclick = () => {
				const checkSelected = Object.values(this.selectedNumbers).find(numberGroup => {
					return numberGroup.indexOf(undefined) > -1
				})


				if(checkSelected == undefined) {
					this.lineHTML()
				} else {
					createNotification('Please choose the rest of the numbers.')
				}

				this.scrollToGame()
			}
		}

		adjustLineNumbers() {
			[...this.numberLines.querySelectorAll('.game-number-line')].map((numberLine, index) => {
				numberLine.querySelector('.game-count').innerHTML = `Game ${index + 1}`
			})
		}

		lineHTML(numbersShuffle = false) {
			this.updateLinesCount()
			const editIcon = `<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <title>np_edit_1450843_FFFFFF</title> <desc>Created with Sketch.</desc> <defs></defs> <g id="MODULE-Account-details" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(-229.000000, -166.000000)"> <g id="User-details" fill="#FFFFFF" fill-rule="nonzero"> <g id="Edit" transform="translate(220.000000, 158.000000)"> <g id="np_edit_1450843_FFFFFF" transform="translate(9.000000, 8.000000)"> <path d="M14.8262521,2.99862912 L11.9627061,0.165675812 C11.7394195,-0.0552252708 11.3440058,-0.0552252708 11.1207192,0.165675812 L0.721162364,10.454129 C0.630916484,10.5424895 0.573235908,10.6575418 0.553698631,10.7817931 L0.00664059243,14.3225724 C-0.0221996958,14.51402 0.04385502,14.7063865 0.186201098,14.8389272 C0.297844381,14.9429357 0.444832438,15 0.59557171,15 C0.636508771,15 0.67744345,14.995398 0.71837813,14.9871142 L4.12900598,14.2783929 C4.24250939,14.2544625 4.34578092,14.199236 4.42765028,14.118239 L14.8262545,3.83167078 C15.0579152,3.60156608 15.0579152,3.22879344 14.8262545,2.99867931 L14.8262521,2.99862912 Z M5.22576339,11.6626641 L3.20501402,9.66350344 L9.21243486,3.72026289 L11.2331842,5.71942358 L5.22576339,11.6626641 Z M3.70927249,13.1629536 L1.31278245,13.6599869 L1.70074137,11.1510219 L2.36221739,10.4966127 L4.38296676,12.4957734 L3.70927249,13.1629536 Z M12.0750758,4.88652635 L10.0543264,2.88736566 L11.5419747,1.41561072 L13.562724,3.41477141 L12.0750758,4.88652635 Z" id="Shape"></path> </g> </g> </g> </g></svg>`
			
			const numberLineNumbers = () => Object.keys(this.selectedNumbers).map(numberType => {
				if(numbersShuffle) {
					const numbersQty = this.gameNumbers[numberType][0]
					const picksQty = this.gameNumbers[numberType][1]

					this.selectedNumbers[numberType] = this.newNumbersArr(numbersQty).sort(() => {return 0.5 - Math.random()}).splice(0, picksQty)

				}

 				const numbersLine = this.selectedNumbers[numberType].map(number => {
 					return `<div class="lottery-number ${numberType}"><span><span>${number}</span></span></div>`
 				}).join('')

				return `<div class="lottery-numbers edit-line">
					${numbersLine}
				</div>`
			}).join('')


			this.numberLine.innerHTML = `${typeof this.linesCount === 'number' && this.linesCount >= 0 ? `<p class="game-count">Game ${this.linesCount}</p>` : ''}
				<div class="number-line--numbers">
					<div class="lottery-numbers-container">
						${numberLineNumbers()}
					</div>
					<div class="lottery-line-actions">
						<span class="favourite-line lottery-favourite ${this.favouriteGame ? 'active' : ''}">${heartIcon}</span>
						<span class="edit-line lottery-edit">${editIcon}</span>
						<span class="remove-line lottery-close">${iconClose}</span>
					</div>
					<input type="hidden" name="${this.gameType}_play_numbers[]" value='${JSON.stringify(this.selectedNumbers)}'>
				</div>`

			this.removeGame()
			this.selectFavourite() 
			this.editGame()

			if(this.addGameButton != undefined) {
				this.gameCost()

				const openGame = this.numberLines.querySelector('.game-numbers--container')
				
				if(!openGame) {
					this.addGameButton.classList.remove('disabled')
				}
			}
		}

		scrollToGame(el = this.numberLine) {
			let container = window

			const navTab = utility.findAncestor(el, 'nav-tab')

			if(navTab) {
				container = navTab
			}

			container.scrollBy({top: el.getBoundingClientRect().top - document.querySelector('#header').offsetHeight - 10, left: 0, behavior: 'smooth' });
		}

		newNumbersArr(qty) {

			const numbersArr = [...Array(qty + 1).keys()]

			if(!this.numberLines.dataset.haszero) {
				numbersArr.shift(0)
			}

			return numbersArr
		}
	}
}

export default lotteryGame