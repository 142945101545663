const styleRange = {
	init: (startColor, endColor) => {
		const rangeInputs = [...document.querySelectorAll('input[type="range"]')]

		rangeInputs.map(rangeInput => {
			styleRange.applyStyles(rangeInput)
			styleRange.positionTooltip(rangeInput)

			rangeInput.addEventListener('change', () => {
				styleRange.applyStyles(rangeInput)
				styleRange.positionTooltip(rangeInput)
			})

			rangeInput.addEventListener('input', () => {
				styleRange.applyStyles(rangeInput)
				styleRange.positionTooltip(rangeInput)
			})
		})
	},

	applyStyles: (rangeInput) => {
		const val = (rangeInput.value - rangeInput.getAttribute('min')) / (rangeInput.getAttribute('max') - rangeInput.getAttribute('min')) * 100;

		rangeInput.style.background = `linear-gradient(to right,transparent 0% ,transparent ${val}% ,#EFEFEF ${val}% ,#EFEFEF 100% )`
		rangeInput.parentNode.style.background = `linear-gradient(to right, #E25F2C 0% , #E25F2C ${val - 0.1}% ,transparent ${val - 0.1}% ,transparent 100% )`

		var posPerc = (rangeInput.value/rangeInput.getAttribute('max')) * 100;
  		var pixPos = (posPerc/100) * rangeInput.clientWidth;
    	pixPos += rangeInput.offsetLeft;
	},

	positionTooltip: (rangeInput) => {

		const inputMin = rangeInput.getAttribute('min')
		const inputMax = rangeInput.getAttribute('max')
		const inputVal = rangeInput.value
		const inputThumbWidth = 20

		const range = inputMax - inputMin;

		const position = ((inputVal - inputMin) / range) * 100;
		const positionOffset = Math.round(inputThumbWidth * position / 100) - (inputThumbWidth / 2);
		const output = rangeInput.parentNode.nextElementSibling;

		if(output) {
			output.style.left = `calc(${position}% - ${positionOffset}px)`
			if(output.querySelector('output')) {
				output.querySelector('output').innerHTML = `Div ${inputVal}`
			}
		}

		
	}
}

export default styleRange