const inputFormat ={

	init: function() {
		const currencyInputs = [...document.querySelectorAll('.currency-format')]
		currencyInputs.map(currencyInput => new this.currencyFormat(currencyInput))
	},

	currencyFormat: class {
		constructor(currencyInput) {
			this.currencyInput = currencyInput

			this.handleInput()
		}

		handleInput() {
			this.currencyInput.oninput = () => {

			}
		}
    
   //  	var $input = $( ".input-currency" );

   //      $input.on( "keyup", function( event ) {
            
   //          var selection = window.getSelection().toString();
            
   //          if ( selection !== '' ) {
   //              return;
   //          }
            
   //          if ( $.inArray( event.keyCode, [38,40,37,39] ) !== -1 ) {
   //              return;
   //          }
            
   //          var $this = $( this );
            
   //          var input = $this.val();

   //          var initLength = input.replace('$', '').length;

   //          var input = input.replace(/[^0-9.]/g, '');

   //          if(input.indexOf('.') > 0) {
	  //           input = input.split('.');

			// 	var preDec = input[0];
			// 	preDec = preDec ? parseFloat( preDec ) : 0;
			// 	preDec = preDec.toLocaleString( "en-US" );

			// 	var postDec = input[1];
			// 	postDec = postDec.substring(0,2);

			// 	input = preDec + '.' + postDec;
				
			// } else {
			// 	input = input ? parseInt( input, 10 ) : 0;
			// 	input = input.toLocaleString( "en-US" );
			// }

			// var caretPosition = forms.inputFormat.getCaretPosition($this[0]);

			// var finalLength = input.length;

   //          $this.val( function() {
   //              return '$' + input;
   //          });

   //          if(caretPosition.start != 1) {
   //          	if(initLength != undefined  && initLength != finalLength) {
   //          		forms.inputFormat.setCaretPosition($this[0], caretPosition.start + 1, caretPosition.end + 1);
   //          	} else {
   //          		forms.inputFormat.setCaretPosition($this[0], caretPosition.start, caretPosition.end);
   //          	}
   //          } else {
   //          	forms.inputFormat.setCaretPosition($this[0], caretPosition.start + 1, caretPosition.end + 1);
   //          }
   //      });
	}
}

export default inputFormat