import utility from './utility'

const handleVideo = () => {
	const videoContainers = [...document.querySelectorAll('.video-container')];

	videoContainers.map(videoContainer => {
		const video = videoContainer.querySelector('video')
		
		const videoControls = videoContainer.querySelector('.video-controls')
		const videoPlay = videoControls.querySelector('.video-play')
		const videoRestart = videoControls.querySelector('.video-restart')
		const videoExpand = videoControls.querySelector('.video-expand')

		videoContainer.onclick = (e) => {
			if(e.target != video) return
			utility.toggleClass(videoControls, 'hidden')
		}

		videoRestart.onclick = () => {
			video.pause()
			video.currentTime = 0
		}

		videoExpand.onclick = () => {
			videoControls.classList.add('hidden')
		
			

			if(video.enterFullscreen) {
				video.enterFullscreen()
			} else if(video.requestFullscreen) {
				video.requestFullscreen()
			} else if(video.webkitEnterFullscreen) {
				video.webkitEnterFullscreen()
			} else if(video.mozRequestFullScreen) { 
				video.mozRequestFullScreen()
			} else if(video.msRequestFullscreen) {
				video.msRequestFullscreen()
			}
		}

		["fullscreenChange", "fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange", "MSFullscreenChange"].forEach(
			eventType => document.addEventListener(eventType, function() {
				video.controls = !video.controls
			}, false)
		);

		videoPlay.addEventListener('click',function(){
			if(video.paused) {
		    	video.play()
				videoControls.classList.add('hidden')
			} else {
				video.pause()
				videoControls.classList.remove('hidden')
			}

		}, false);
	})
}

export default handleVideo
