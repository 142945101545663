const utility = {
	toggleClass: function(el, className){
		if (el.classList) {
		  el.classList.toggle(className);
		} else {
		  var classes = el.className.split(' ');
		  var existingIndex = classes.indexOf(className);

		  if (existingIndex >= 0)
		    classes.splice(existingIndex, 1);
		  else
		    classes.push(className);

		  el.className = classes.join(' ');
		}
	},

	addClass: function(el, className) {
		if (el.classList)
		  el.classList.add(className);
		else
		  el.className += ' ' + className;
	},

	removeClass: function(el, className){
		if (el.classList)
		  el.classList.remove(className);
		else
		  el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	},

	findAncestor: function(el, cls) {
	    while ((el = el.parentElement) && !el.classList.contains(cls));
	    return el;
	},

	htmlScroll: function(state)  {
		const body = document.body

		const preventScroll = e => {
			e.preventDefault()
		}
		
		if(state === 'hidden') {
			if(window.innerWidth < 760) {
				document.documentElement.style.position = 'fixed'
			}

			body.style.overflow = 'hidden'
			body.classList.add('scroll-hidden')

			return
		}

		if(state === 'initial') {
			if(window.innerWidth < 760) {
				document.documentElement.style.position = 'static'			
			}
			
			body.style.overflow = 'scroll'
			body.classList.remove('scroll-hidden')
			body.classList.remove('popup-active')

			return
		}

		body.style.overflow = body.style.overflow === 'hidden' ? 'initial' : 'hidden' 

	},

	scrollTo: (el, container = window) => {
		container.scrollBy({top: el.getBoundingClientRect().top - document.querySelector('#header').offsetHeight - 10, left: 0, behavior: 'smooth' });
	}
}

export default utility

