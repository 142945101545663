const timer = {
	init: function() {
		const countdownTimers = [...document.querySelectorAll('.timer-countdown')]

		countdownTimers.map(countdownTimer => {
			if(countdownTimer.dataset.date) {
				const date = countdownTimer.dataset.date;
				timer.countdown(countdownTimer, date);
			}
		})
	},

	countdown: function(el, date) {

		// Set the date we're counting down to
		var countDownDate = new Date(date).getTime();

		var x = setInterval(function() {

		  // Get todays date and time

		  var offset = -(new Date().getTimezoneOffset() / 60);
		  
		  var now = new Date();
		  now.setHours(now.getHours() - offset + 10);
		  now.getTime();


		  // Find the distance between now an the count down date
		  var distance = countDownDate - now;

		  // Time calculations for days, hours, minutes and seconds
		  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

		  if(el.classList.contains('cta-countdown')) {
		  	days = days.toString().split('');
			hours = hours.toString().split('');
			minutes = minutes.toString().split('');
			seconds = seconds.toString().split('');

			days = timer.countMap(days);
			hours = timer.countMap(hours);
			minutes = timer.countMap(minutes);
			seconds = timer.countMap(seconds);

			el.innerHTML = '<div class="cta-timer-container">' + (days > 0 ? '<div><span class="cta-timer-count">'+days + '</span><span>Days</span></div>' : '') + '<div><span class="cta-timer-count">'+hours + '</span><span>Hours</span></div>' + '<div><span class="cta-timer-count">' + minutes + '</span><span>Minutes</span></div>' + (days <= 0 ? '<div><span class="cta-timer-count">' + seconds + '</span><span class="count-units">Seconds</span></div>' : '') + '</div>'

		  } else {
		  // Display the result in the element with id="demo"
			el.innerHTML = `
				${days > 0 
					? days + (days != 1 ? ' Days, ' : ' Day, ') + hours + (hours != 1 ? ' Hrs' : ' Hr')
						: (hours > 0
						? hours + (hours != 1 ? ' Hrs, ' : ' Hr, ') + minutes + (minutes != 1 ? ' Mins' : ' Min') 
					: minutes + (minutes != 1 ? ' Mins, ' : ' Min, ') + seconds + (seconds != 1 ? ' Secs' : ' Sec')
					)
				}
			`;
		  }

		  // If the count down is finished, write some text 
		  if (distance < 0) {
		    clearInterval(x);
		    el.innerHTML = 'EXPIRED';
		  }
		}, 1000);
	}, 

	countMap: function(elmap){
		return elmap.map(function(num){
			return num;
		}).join('');
	}
}

export default timer