import "core-js";
import "babel-polyfill";

import misc from "./classes/misc";
import lazyLoad from "./classes/lazyLoad";

import lotteryTabs from "./classes/lotteryTabs";

import styleSelect from "./classes/styleSelect";
import styleRange from "./classes/styleRange";
import radioLabel from "./classes/radioLabel";
import inputFormat from "./classes/inputFormat";
import documentUpload from "./classes/documentUpload";

import timer from "./classes/timer";

import lotteryGame from "./classes/lotteryGame";
import lotteryQuickPlay from "./classes/lotteryQuickPlay";
import lotteryResults from "./classes/lotteryResults";
import lotteryHowTo from "./classes/lotteryHowTo";
import lotteryCombo from "./classes/lotteryCombo";

import accountFunds from "./classes/accountFunds";

import cardFlip from "./classes/lotteryCard";
import handleVideo from "./classes/video";

import luke from "./classes/luke";

document.addEventListener("DOMContentLoaded", function() {
  timer.init();
  misc.init();
  lazyLoad.init();

  styleRange.init();
  radioLabel.init();
  inputFormat.init();
  documentUpload.init();

  lotteryGame.init();
  lotteryQuickPlay.init();
  lotteryResults.init();
  lotteryHowTo.init();
  lotteryCombo.init();

  accountFunds.init();

  cardFlip.init();

  styleSelect.init("select");
  lotteryTabs.init();

  handleVideo();

  luke.init();
});
