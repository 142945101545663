import utility from "./utility";
import Flickity from "flickity";

const misc = {
  init: function() {
    const _this = this;

    this.autoCompleteOff();
    this.logoShrink();
    this.mainAdjustPos.init();
    this.toolTips();
    this.popUps();
    this.hashScroll();
    this.validatePassword();
    this.togglePassword();
    this.toggleClass();

    window.addEventListener("load", () => {
      _this.lotterySliders();
      _this.sliders();
    });

    const parallaxItems = [...document.querySelectorAll(".section-parallax")];

    parallaxItems.map(parallaxItem => {
      _this.parallax(15, parallaxItem);
    });

    const navItems = [...document.querySelectorAll("#header-top-nav > li")];

    navItems.map(item => {
      item.addEventListener("click", e => {
        const desktopItem = item.querySelector(".top-nav--desktop-item");

        if (desktopItem) {
          if (
            utility.findAncestor(e.target, "top-nav--desktop-item") === null
          ) {
            const activeItem = navItems.find(navItem =>
              navItem.classList.contains("nav-active")
            );

            item.classList.add("nav-active");

            if (activeItem) {
              activeItem.classList.remove("nav-active");
            }
          }
        }
      });
    });

    window.addEventListener("click", e => {
      if (
        !utility.findAncestor(e.target, "nav-active") &&
        !e.target.classList.contains("nav-active")
      ) {
        const activeNavItem = document.querySelector(
          "#header-top-nav > li.nav-active"
        );

        if (activeNavItem) {
          activeNavItem.classList.remove("nav-active");
        }
      }
    });
  },

  autoCompleteOff: () => {
    [...document.querySelectorAll("input", "textarea")].map(
      input => (input.autocomplete = "asdasdas")
    );
  },

  navExpand: () => {
    const navExpandButton = document.querySelector("#nav-expand");

    navExpandButton.onclick = () => {
      utility.toggleClass(document.body, "nav-active");
    };
  },

  logoShrink: () => {
    window.addEventListener("scroll", () => {
      const windowOffset = window.scrollY;
      const scrollDisabled = document.body.classList.contains("scroll-hidden");

      if (scrollDisabled) return;

      if (windowOffset > 0) {
        utility.addClass(document.body, "nav-small");
      } else {
        utility.removeClass(document.body, "nav-small");
      }
    });
  },

  mainAdjustPos: {
    init: function() {
      const _this = this;

      _this.adjustPos();

      window.addEventListener("scroll", () => {
        setTimeout(function() {
          _this.adjustPos();
        }, 300);
      });
    },

    adjustPos: () => {
      const main = document.querySelector("#main");
      const userEdit = document.querySelector("#user-edit");
      let headerHeight = document.querySelector("#header").offsetHeight;

      if (userEdit) {
        headerHeight = headerHeight - userEdit.offsetHeight;
      }
      main.style.top = `${headerHeight}px`;
    }
  },

  parallax: (intensity, element) => {
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      const imgPos = (scrollTop - element.offsetTop / 1.7) / intensity + "px";

      element.style.top = `calc(50% - ${imgPos})`;

      element.style.top = imgPos;
    });
  },

  lotterySliders: function() {
    const lotterySliders = [
      ...document.querySelectorAll(".lottery-slider--slider")
    ];

    lotterySliders.map(lotterySlider => {
      const slides = lotterySlider.querySelectorAll(".lottery-slider--slide");
      if (slides.length <= 4) {
        lotterySlider.classList.add("inactive");
      }

      if (slides.length <= 3) {
        lotterySlider.classList.add("inactive-3");
      }

      let tallestSlide = 0;

      slides.forEach(slide => {
        if (slide.offsetHeight > tallestSlide) {
          tallestSlide = slide.offsetHeight;
        }
      });

      slides.forEach(slide => {
        slide.style.minHeight = tallestSlide + "px";
      });

      const flkty = new Flickity(lotterySlider, {
        prevNextButtons: false,
        autoPlay: 3000,
        resize: true,
        wrapAround: true,
        cellAlign: "left",
        imagesLoaded: true,
        on: {
          ready: function() {
            lotterySlider.classList.add("ready");
          }
        }
      });
    });
  },

  sliders: function() {
    const sliders = [...document.querySelectorAll(".slider")];

    sliders.map(slider => {
      const flkty = new Flickity(slider, {
        autoPlay: 2000,
        wrapAround: true,
        selectedAttraction: 0.01,
        pageDots: false,
        controls: false,
        prevNextButtons: false,
        imagesLoaded: true,
        on: {
          ready: function() {
            slider.classList.add("ready");
          }
        }
      });
    });
  },

  toolTips: () => {
    [...document.querySelectorAll(".tooltip-action")].map(toolTip => {
      const tooltipContent = document.querySelector(
        `#${toolTip.dataset.tooltip}`
      );

      toolTip.onclick = () => {
        tooltipContent.classList.toggle("active");
      };

      document.addEventListener("click", function(event) {
        const isClickInside =
          tooltipContent.contains(event.target) ||
          utility.findAncestor(toolTip, "input-tooltip").contains(event.target);

        if (!isClickInside && tooltipContent.classList.contains("active")) {
          utility.removeClass(tooltipContent, "active");
        }
      });
    });
  },

  popUps: () => {
    const bodyHidden = document.querySelector("body.scroll-hidden");
    if (bodyHidden) {
      utility.htmlScroll("hidden");
    }

    [...document.querySelectorAll(".popup-close")].map(popUpClose => {
      popUpClose.onclick = () => {
        [...document.querySelectorAll(".popup")].map(popup => {
          popup.classList.remove("active");
          utility.htmlScroll("initial");
        });
      };
    });
  },

  hashScroll: () => {
    [...document.querySelectorAll('a[href*="#"]')].forEach(hashClick => {
      hashClick.addEventListener("click", function(e) {
        let href = this.getAttribute("href");
        href = href.substring(href.indexOf("#") + 1);
        const target = document.getElementById(href);

        if (!target) return;

        e.preventDefault();

        const header = document.getElementById("header");
        target.style.paddingTop = `${header.offsetHeight + 25}px`;
        target.style.marginTop = `-${header.offsetHeight + 25}px`;

        window.scrollBy({
          behavior: "smooth",
          left: 0,
          top: target.getBoundingClientRect().top
        });
      });
    });
  },

  validatePassword: () => {
    [...document.querySelectorAll(".password-strength")].map(
      passwordContainer => {
        const passwordInput = passwordContainer.querySelector(
          ".password-strength--password"
        );
        const passwordValidation = passwordContainer.querySelector(
          ".password-strength--validation"
        );

        passwordInput.addEventListener("input", e => {
          const value = e.target.value;
          const valueLength = value.length >= 8;
          const valueCap = value.match(/[a-z]/) && value.match(/[A-Z]/);
          const valueNumber = value.match(/\d+/);
          // const valueSpecial = !value.match(/^[a-zA-Z0-9- ,_]*$/)

          const validationArr = [
            {
              error: "Use at least 8 characters.",
              validation: valueLength
            },
            {
              error: "Use upper and lower case characters.",
              validation: valueCap
            },
            {
              error: "Use 1 or more numbers.",
              validation: valueNumber
            }
            // {
            // 	error: 'Use 1 special character.',
            // 	validation: valueSpecial
            // }
          ];

          const validationCount = validationArr.filter(
            ({ validation }) => validation
          ).length;

          // <div
          // 	class="password-strength--meter"
          // 	style="max-width: ${((validationCount + 1) / (validationArr.length + 1)) * 100}%; background-color: ${{
          // 		0: 'red',
          // 		1: 'red',
          // 		2: 'orange',
          // 		3: 'orange',
          // 		4: 'green'
          // 	}[validationCount]}"
          // ></div>

          const validationMessage = `
					<p class="password-strength--title" style="color: ${
            {
              0: "red",
              1: "red",
              2: "orange",
              3: "green"
            }[validationCount]
          }">${
            {
              0: "Very Weak",
              1: "Weak",
              2: "Medium",
              3: "Very Strong"
            }[validationCount]
          }
					 Password</p>
					${validationArr
            .map(({ validation, error }) => {
              return `<p>${
                !validation
                  ? '<svg xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>'
                  : '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>'
              } ${error}</p>`;
            })
            .join("")}
				`;

          if (!validationArr.find(validation => !validation)) {
            passwordValidation.innerHTML = validationMessage;
          }
        });
      }
    );
  },

  togglePassword: () => {
    [...document.querySelectorAll(".toggle-password")].map(toggle => {
      toggle.onclick = () => {
        const passwordInput = toggle.parentNode.querySelector("input");
        toggle.classList.toggle("active");

        passwordInput.type =
          passwordInput.type === "password" ? "text" : "password";
      };
    });
  },

  toggleClass: () => {
    document.addEventListener("click", function(event) {
      //Bail if our clicked element doesn't have the class
      if (!event.target.classList.contains("trigger")) return;

      // Get the target content
      var content = document.querySelector(event.target.hash);
      if (!content) return;

      // Prevent default link behavior
      event.preventDefault();

      // If the content is already expanded, collapse it and quit
      if (content.classList.contains("active")) {
        content.classList.remove("active");
        return;
      }

      // Get all open accordion content, loop through it, and close it
      var accordions = document.querySelectorAll(".white-box--shadow.active");
      for (var i = 0; i < accordions.length; i++) {
        accordions[i].classList.remove("active");
      }

      // Toggle our content
      content.classList.toggle("active");
    });
  }
};

export default misc;
