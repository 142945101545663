export default function createNotification(message) {
	let notificationsContainer = document.querySelector('.notifications-container')

	if(!notificationsContainer) {
		notificationsContainer = document.createElement('div')
		document.body.appendChild(notificationsContainer)
		notificationsContainer.classList.add('notifications-container')
	}

	const notification = document.createElement('div')
	notification.classList.add('notification')
	notification.innerHTML = message
	notificationsContainer.appendChild(notification)

	if(notificationsContainer.querySelectorAll('.notification').length > 5) {
		const firstNotification = notificationsContainer.querySelector('.notification')
		firstNotification.parentNode.removeChild(firstNotification)
	}

	setTimeout(function() {
		if(notification.parentNode) notification.parentNode.removeChild(notification)
	}, 10000)

	notification.onclick = () => {
		notification.parentNode.removeChild(notification)
	}
}