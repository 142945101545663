import utility from './utility'

const lotteryResults = {
	init: function() {
		const _this = this
		_this.resultExpandClick()
	},

	resultExpandClick: () => {
		const resultExpands = [...document.querySelectorAll('.results-expand')]

		resultExpands.map(resultExpand => {
			const divisionResult = resultExpand.nextElementSibling
			const parentExpand = utility.findAncestor(resultExpand, 'results-content')
			const expandText = resultExpand.innerHTML
			const expandLessText = resultExpand.dataset.subtext

			if(!divisionResult.classList.contains('results-content')) {
				return
			}

			resultExpand.onclick = () => {
				utility.toggleClass(resultExpand, 'active')

				if (resultExpand.classList.contains('active')) {
					divisionResult.style.maxHeight = `${divisionResult.scrollHeight}px`

					if(parentExpand) {
						parentExpand.style.maxHeight = `${parentExpand.scrollHeight + divisionResult.scrollHeight + 100}px`
					}
				} else {
					divisionResult.style.maxHeight = 0
				}
				

				if(expandLessText) {
					if(!resultExpand.classList.contains('active')) {
						resultExpand.querySelector('span').innerHTML = expandText
					} else {
						resultExpand.querySelector('span').innerHTML = expandLessText
					}
				}
			}
		})
	}
}

export default lotteryResults