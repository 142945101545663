import utility from './utility'

const luke = {
	init: function() {
		const forms = [...document.querySelectorAll('.test-form')]

		forms.map(form => {
			form.onsubmit = e => {
				e.preventDefault()

								// *** THIS WILL ONLY WORK ON POPUPS *** //
								// PASS DOWN A 'form' VARIABLE that targets the form
								// RUN THIS AFTER ERRORS HAVE BEEN ADDED
								const firstError = form.querySelector('.has-error')
								const popupContainer = utility.findAncestor(form, 'popup')

								var parentPos = popupContainer.getBoundingClientRect()
								var childrenPos = firstError.getBoundingClientRect()

								popupContainer.scrollBy({
									top: childrenPos.top - parentPos.top - 20,
									left: 0, 
									behavior: 'smooth' 
								})




								
			}
		})
	}
}

export default luke