import utility from './utility'


const lotteryQuickPlay = {
    init: function() {
        const quickPlays = [...document.querySelectorAll('.lottery-card--quickplay')]
        let timeout;

        function handleHeader() {
            timeout = setTimeout(() => {
                const header = document.querySelector('#header')
                utility.removeClass(header, 'cart-active')
            }, 3000)
        }
        
        
        quickPlays.map(quickPlay => {
            quickPlay.onclick = () => {
                if(quickPlay.classList.contains('active')) return
                const header = document.querySelector('#header')
                
                utility.addClass(quickPlay, 'active')

                if(window.innerWidth > 980) {
                    setTimeout(() => {
                        clearTimeout(timeout)
                        utility.addClass(header, 'cart-active')
                        handleHeader()
                    }, 1000)
                } else {
                    setTimeout(() => {
                        window.location.href = '/checkout'
                    }, 800)   
                }

                setTimeout(() => {
                    utility.removeClass(quickPlay, 'active')
                }, 2000)
            }
        })
    }
}

export default lotteryQuickPlay