const lotteryCombo = {
	init: () => {
        [...document.querySelectorAll('.lottery-card--combo')].map(comboCard =>
            new lotteryCombo.handleCombo(comboCard)
        )
    },
    
    handleCombo: class {
        constructor(comboCard) {
            this.price = comboCard.dataset.price
            this.qty = comboCard.querySelector('.qty')
            this.totalContainer = comboCard.querySelector('.total span')
            
            this.minus = comboCard.querySelector('.input-minus')
            this.plus = comboCard.querySelector('.input-plus')


            this.handleChange()
            this.handleQty()
        }

        handleChange() {
            this.qty.onkeypress = e => {
                if (e.which < 48 || e.which > 57) {
                    e.preventDefault()
                }
            }

            this.qty.oninput = () => {
                if(this.qty.value > 99) {
                    this.qty.value = 99
                }
                
                this.handleTotal()
            }
        }

        handleQty() {
            this.minus.onclick = () => {
                this.handleQtyChange()
            }

            this.plus.onclick = () => {
                this.handleQtyChange(true)
            }
        }

        handleQtyChange(direction) {
            const qty = Number(this.qty.value || 0)

            if(direction) {
                this.qty.value = qty >= 99 ? qty : qty + 1
            } else {
                this.qty.value = qty > 0 ? qty - 1 : 0
            }

            this.handleTotal()
        }

        handleTotal() {
            this.totalContainer.innerHTML = (Number(this.qty.value) * Number(this.price)).toFixed(2)
        }
    }
}

export default lotteryCombo