import utility from './utility'
import Flickity from "flickity";

const cardFlip = {
	init: function() {
		const _this = this

		const cardFlips = [...document.querySelectorAll('.flip-trigger')]

		cardFlips.map(cardFlip => {
			cardFlip.onclick = () => {
				const slider = utility.findAncestor(cardFlip, 'lottery-slider--slider')
				const lotteryCard = utility.findAncestor(cardFlip, 'lottery-card-flip')
					const cardHeight = lotteryCard.offsetHeight

				utility.toggleClass(lotteryCard, 'flip')


				if (lotteryCard.classList.contains('flip')) {
					utility.removeClass(lotteryCard, 'flip-front')
				} else {
					utility.addClass(lotteryCard, 'flip-front')
				}
				

				if(slider != undefined) {
					_this.resizeSlider(slider)
				} 

				if(cardFlip.classList.contains('flip-stretch')) {
					lotteryCard.querySelector('.flipper').style.minHeight = `${cardHeight}px`
				}

				cardFlip.style.opacity = 0
				cardFlip.style.visibility = 'hidden'

				setTimeout(() => {
					cardFlip.style.opacity = 1
					cardFlip.style.visibility = 'visible'
				}, 200)
			}
		})
	},

	resizeSlider: (slider) => {
		var flkty = new Flickity(slider);
		flkty.resize()
	}
}

export default cardFlip